import React from 'react';

const AboutUsText = () => {
  return (
    <>
      <h2 className="text-[25px] md:text-[50px] mb-[15px]">Sobre nós</h2>
      <p className="text-[16px] leading-[150%]">
        Metalúrgica Cipriani é especializada na produção de gabinetes, e peças
        em geral em aço carbono e alumínio para indústria eletro eletrônica,
        mecânica etc. Também contamos com um equipamento de corte por jato
        água,esta maquina pode cortar diversos tipos de materiais como aço,
        alumínio, cobre, latão, pvc, níquel e cerâmica.
      </p>
      <br />
      <p className="text-[16px] leading-[150%]">
        Há 27 anos no mercado, fabricamos gabinetes para equipamentos
        eletrônicos, peças em chapa de aço ou alumínio, painéis, suportes,
        dissipadores e mais! Faça um orçamento conosco.
      </p>
    </>
  );
};

export default AboutUsText;
