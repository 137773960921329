import React from 'react';
import { Link } from 'react-router-dom';
import NavLinks from '../Navigation/NavLinks';

const Header = () => {
  return (
    <header className="bg-blue-gray text-white relative lg:min-h-[90px] flex items-center justify-end pb-[20px] shadow-header min-h-[60px] lg:flex-row flex-col gap-y-[10px] lg:gap-y-0 pt-[20px]">
      <button aria-label="Instalar App" className="ad2hs-prompt" hidden>
        Instalar App
      </button>
      <Link
        to="/"
        className="lg:absolute lg:left-[50%] lg:translate-x-[-50%] md:text-[32px] text-[25px]"
      >
        Metalúrgica Cipriani
      </Link>
      <NavLinks />
    </header>
  );
};

export default Header;
