import React from 'react';

import Video2 from '../../assets/videos/video4.mp4';
import Video3 from '../../assets/videos/video5.mp4';
import Video4 from '../../assets/videos/video6.mp4';

const HomeVideos = () => {
  return (
    <>
      <h3 className="text-center text-[30px] md:text-[45px] mb-[15px]">
        Nossas máquinas
      </h3>
      <div className="flex flex-col md:flex-row md:justify-between md:flex-wrap md:gap-y-[30px]">
        <div className="w-full md:w-[50%] lg:w-[30%] mb-[40px] md:mb-0">
          <video className="h-[300px] w-full" controls loop>
            <source src={Video4} type="video/mp4" />
          </video>
        </div>
        <div className="w-full md:w-[50%] lg:w-[30%] mb-[40px] md:mb-0">
          <video className="h-[300px] w-full" controls loop>
            <source src={Video2} type="video/mp4" />
          </video>
        </div>
        <div className="w-full md:w-[50%] lg:w-[30%] mb-[40px] md:mb-0">
          <video className="h-[300px] w-full" controls loop>
            <source src={Video3} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
};

export default HomeVideos;
