import React from 'react';
import ImageViewer from 'react-simple-image-viewer';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Product1 from '../../assets/images/produto1.jpg';
import Product3 from '../../assets/images/produto3.jpg';
import Product2 from '../../assets/images/produto10.jpg';
import Product4 from '../../assets/images/produto15.jpg';
import Product6 from '../../assets/images/produto18.jpg';
import Product5 from '../../assets/images/produto19.jpg';
import Product7 from '../../assets/images/produto22.jpeg';
import Product8 from '../../assets/images/produto23.jpeg';
import Product9 from '../../assets/images/produto24.jpeg';
import Product10 from '../../assets/images/produto25.jpeg';
import Product11 from '../../assets/images/produto26.jpeg';
import Product12 from '../../assets/images/produto27.jpeg';
import Product13 from '../../assets/images/produto28.jpeg';
import Product14 from '../../assets/images/produto29.jpeg';
import Product15 from '../../assets/images/produto30.jpeg';
import Product16 from '../../assets/images/produto33.png';
import { useState } from 'react';
import { useCallback } from 'react';

const HomeProductsCarousel = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const settings = {
    lazyLoad: true,
    dots: true,
    infinite: true,
    arrows: true,
    autoplay: true,
    draggable: false,
    centerMode: true,
    autoplaySpeed: 1500,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          autoplaySpeed: 2000,
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  const products = [
    Product1,
    Product2,
    Product3,
    Product4,
    Product5,
    Product6,
    Product7,
    Product8,
    Product9,
    Product10,
    Product11,
    Product12,
    Product13,
    Product14,
    Product15,
    Product16,
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <h3 className="text-center text-[30px] md:text-[45px]">
        Nossos produtos
      </h3>
      <p className="text-center w-full mb-[30px]">
        <small>Clique nas imagens para expandir</small>
      </p>
      <Slider {...settings}>
        {products.map((product, index) => (
          <div key={index} className="pr-[15px]">
            <img
              alt={`Produto ${index}`}
              onClick={() => openImageViewer(index)}
              src={product}
              data-lazy={product}
              className="w-full h-[215px] rounded-md"
            />
          </div>
        ))}
      </Slider>
      {isViewerOpen && (
        <ImageViewer
          src={products}
          currentIndex={currentImage}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}
    </>
  );
};

export default HomeProductsCarousel;
