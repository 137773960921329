import React from 'react';
import whatsappIcon from '../../assets/icons/icons8-whatsapp-64.svg';

const WhatsappIcon = () => {
  return (
    <div className="whatsapp-icon">
      <a
        href="https://api.whatsapp.com/send?phone=554899692910"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={whatsappIcon} alt="Ícone do Whatsapp" />
      </a>
    </div>
  );
};

export default WhatsappIcon;
