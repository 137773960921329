import React from 'react';
import HomeCarousel from '../components/Carousel/HomeCarousel';
import HomeProductsCarousel from '../components/Carousel/HomeProductsCarousel';
import HomeVideos from '../components/Video/HomeVideos';

const HomePage = () => {
  return (
    <>
      <div className="mb-[50px] md:mb-[80px]">
        <HomeCarousel />
      </div>
      <div className="mb-[50px] md:mb-[80px]">
        <HomeVideos />
      </div>
      <div className="mb-[50px] md:mb-[100px]">
        <HomeProductsCarousel />
      </div>
    </>
  );
};

export default HomePage;
