import React from 'react';
import ImageViewer from 'react-simple-image-viewer';
import ProductItem from '../components/Products/ProductItem';

import Image2 from '../assets/images/maquina2.jpg';
import Image3 from '../assets/images/maquina10.png';
import Image4 from '../assets/images/maquina4.jpeg';
import Image5 from '../assets/images/maquina5.jpeg';
import Image6 from '../assets/images/maquina6.jpeg';
import { useState } from 'react';
import { useCallback } from 'react';

const ProductsPage = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const products = [
    {
      image: Image2,
      description: 'Puncionadeira',
    },
    {
      image: Image4,
      description: 'Dobradeira Hidráulica',
    },
    {
      image: Image5,
      description: 'Guilhotina Hidráulica',
    },
    {
      image: Image6,
      description: 'Máquina Corte a Laser',
    },
    {
      image: Image3,
      description: 'Dobradeira Hidráulica',
    },
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const images = products.map((product) => product.image);

  return (
    <>
      <h2 className="text-[25px] md:text-[50px] mb-[15px]">Máquinas</h2>
      <p className="text-[16px] leading-[150%] mb-[40px]">
        Essas são as nossas máquinas que utilizamos para entregar o melhor da
        metalurgia para nossos clientes. Todas elas são máquinas da melhor
        qualidade que oferecem segurança e qualidade na entrega de nossas peças.
        Abaixo temos algumas das máquinas mais usadas pela Metalúrgica Cipriani.
        <br />
        <br />
        Clique nas images para expandir.
      </p>
      <div className="grid md:grid-cols-[repeat(2,1fr)] lg:grid-cols-[repeat(3,1fr)] place-items-center mb-[50px] md:mb-[80px] gap-y-[15px] gap-x-[15px]">
        {products.map((product, index) => (
          <ProductItem
            onClick={() => openImageViewer(index)}
            image={product.image}
            description={product.description}
            key={index}
          />
        ))}
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </div>
    </>
  );
};

export default ProductsPage;
