import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import React from 'react';

const FooterInfo = () => {
  return (
    <div className="md:w-[50%] w-full mb-[30px] md:mb-0">
      <h5 className="text-[25px]">Informações</h5>
      <p className="my-[15px]">
        <FontAwesomeIcon icon={faPhone} className="mr-[10px]" />
        <a href="tel: +55 (48) 3283-6037">(48) 3283-6037</a>
      </p>
      <p className="my-[15px]">
        <FontAwesomeIcon icon={faWhatsapp} className="mr-[10px]" />
        <a
          href="https://api.whatsapp.com/send?phone=554899692910"
          target="_blank"
          rel="noopener noreferrer"
        >
          (48) 9969-2910
        </a>
      </p>
      <a href="mailto:metalurgicacipriani14@gmail.com" className="my-[15px]">
        <FontAwesomeIcon icon={faEnvelope} className="mr-[10px]" />
        metalurgicacipriani14@gmail.com
      </a>
      <p className="my-[15px]">
        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-[10px]" />
        <a
          href="https://goo.gl/maps/sfTXFWM1Qai3oLDe6"
          target="_blank"
          rel="noopener noreferrer"
        >
          Rua dos Biguás - n° 11 - Pedra Branca 88137285 Palhoça
        </a>
      </p>
      <a
        href="https://www.facebook.com/ciprianimetalurgica/"
        target="_blank"
        rel="noreferrer noopener"
        className="my-[15px]"
      >
        <FontAwesomeIcon icon={faFacebookF} className="mr-[10px]" /> Metalúrgica
        Cipriani
      </a>
    </div>
  );
};

export default FooterInfo;
