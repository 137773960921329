import { Routes, Route, Navigate } from 'react-router-dom';
import Footer from './components/Common/Footer';
import Header from './components/Common/Header';
import AboutUsPage from './pages/AboutUsPage';
import HomePage from './pages/HomePage';
import ProductsPage from './pages/ProductsPage';
import WhatsappIcon from './components/Common/WhatsappIcon';

function App() {
  return (
    <>
      <Header />
      <main className="mt-[20px] xl:max-w-[1420px] lg:max-w-[1000px] max-w-[90%] mx-auto">
        <Routes>
          <Route exact path="/" element={<HomePage />}></Route>
          <Route exact path="/maquinas" element={<ProductsPage />}></Route>
          <Route exact path="/sobre" element={<AboutUsPage />}></Route>
          <Route exact path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      </main>
      <Footer />
      <WhatsappIcon />
    </>
  );
}

export default App;
