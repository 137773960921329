import React from 'react';
import FooterInfo from '../Footer/FooterInfo';
import FooterLinks from '../Footer/FooterLinks';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-dark-gray text-white pt-[30px]">
      <div className="mx-auto flex mb-[30px] xl:max-w-[1250px] lg:max-w-[1000px] max-w-[90%] md:flex-row flex-col">
        <FooterInfo />
        <FooterLinks />
      </div>
      <div className="min-h-[50px] overflow-hidden flex items-center justify-center py-[10px] bg-blue-gray">
        <small className="text-[15px]">
          &copy; {currentYear} Metalúrgica Cipriani
        </small>
      </div>
    </footer>
  );
};

export default Footer;
