import React from 'react';
import { Link } from 'react-router-dom';

const FooterLinks = () => {
  return (
    <div className="w-full md:w-[50%] lg:ml-[35%]">
      <h5 className="text-[25px]">Outros links</h5>
      <ul>
        <li className="my-[15px]">
          <Link to="/sobre">Sobre nós</Link>
        </li>
        <li className="my-[15px]">
          <Link to="/maquinas">Nossas máquinas</Link>
        </li>
      </ul>
    </div>
  );
};

export default FooterLinks;
