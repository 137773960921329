import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Image1 from '../../assets/images/metalurgica3.jpeg';
import Image2 from '../../assets/images/galpao.jpg';

const HomeCarousel = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          autoplaySpeed: 3000,
          slidesToShow: 1,
          dots: false,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        <div>
          <img
            src={Image1}
            alt="Metalúrgica"
            className="w-full md:h-[500px] h-[300px]"
          />
        </div>
        <div>
          <img
            src={Image2}
            alt="Máquinas da Firma"
            className="w-full md:h-[500px] h-[300px]"
          />
        </div>
      </Slider>
    </>
  );
};

export default HomeCarousel;
