import React from 'react';

const AboutUsLocation = () => {
  return (
    <>
      <h2 className="text-[25px] md:text-[40px]">Localização</h2>
      <br />
      <iframe
        title="Localizacao Metalurgica"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3534.9903691925506!2d-48.67707488439566!3d-27.624814929932562!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x952735836972b603%3A0x1e62672ef5490ee1!2sR.%20dos%20Bigu%C3%A1s%2C%2011%20-%20Cidade%20Universit%C3%A1ria%20Pedra%20Branca%2C%20Palho%C3%A7a%20-%20SC!5e0!3m2!1spt-BR!2sbr!4v1589429765583!5m2!1spt-BR!2sbr"
        width="100%"
        height="450"
        frameborder="0"
        style={{ border: 0 }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </>
  );
};

export default AboutUsLocation;
