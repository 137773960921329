import React from 'react';
import AboutUsLocation from '../components/AboutUs/AboutUsLocation';
import AboutUsText from '../components/AboutUs/AboutUsText';

const AboutUsPage = () => {
  return (
    <>
      <div className="mb-[30px]">
        <AboutUsText />
      </div>
      <div className="mb-[40px] md:mb-[80px]">
        <AboutUsLocation />
      </div>
    </>
  );
};

export default AboutUsPage;
