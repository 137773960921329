import React from 'react';
import { Link } from 'react-router-dom';

const NavLinks = () => {
  return (
    <nav>
      <ul className="flex">
        <li className="px-[15px]">
          <Link to="/sobre" className="hover:underline">
            Sobre nós
          </Link>
        </li>
        <li className="px-[15px]">
          <Link to="/maquinas" className="hover:underline">
            Nossas máquinas
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default NavLinks;
