import React from 'react';

const ProductItem = ({ image, description, onClick }) => {
  return (
    <div className="border px-[20px] pt-[40px] pb-[20px] h-[500px] w-full">
      <img
        onClick={onClick}
        src={image}
        alt={description}
        className="w-full h-[350px] cursor-pointer"
      />
      <h4 className="text-center text-[20px] md:text-[25px] mt-[15px]">
        {description}
      </h4>
    </div>
  );
};

export default ProductItem;
